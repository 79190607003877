<template>
    <div>
        <a-row style="margin-bottom:10px;">
          <a-form layout="inline">
            <a-form-item label="查询清洁屋">
              <a-input v-model="params.houseName" style="width:200px;" allowClear />
            </a-form-item>
            <a-form-item label="小区名称">
              <a-input v-model="params.centerName" style="width:200px;" allowClear />
            </a-form-item>
            <a-form-item >
              <a-button @click="onSearch" type="primary">查询</a-button>
            </a-form-item>
          </a-form>
        </a-row>
        <a-table :data-source="data" :pagination="pagination" :columns="columns" :loading="loading">
            <span slot="action" slot-scope="text, record">
                <a-button type="link" @click="viewDetail(record)">查看详情</a-button>
            </span>
        </a-table>
        <detail ref="detail"/>
    </div>
</template>

<script>
import util from '@/utils/util'
import moment from 'moment'
import { queryStreetDeviceApi } from '@/api/center'
import detail from './component/detail'
export default {
    components: { detail },
    data() {
        return{
            loading: false,
            data: [],
            params: {
                page: 1,
                limit: 10,
                startTime: '',
                endTime: '',
                houseName: '',
                centerName: ''
            },
            types: [],
            pagination: {
                pageSize: 10,
                current: 1,
                total: 0,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total) => {
                return '共' + total + '条'
                },
                pageSizeOptions: ['10', '20', '30', '40'],
                onChange: (current, size) => this.changePage(current, size),
                onShowSizeChange:(current, pageSize)=>this.changePage(current, pageSize),
            },
            columns: [
                {
                    title: '设备编号',
                    dataIndex: 'equipmentCode',
                    key: 'equipmentCode'
                },
                // {
                //     title: '生产厂商',
                //     dataIndex: 'manufacturerName',
                //     key: 'manufacturerName'
                // },
                {
                    title: '清洁屋名称',
                    dataIndex: 'pointName',
                    key: 'pointName'
                },
                {
                    title: '小区名称',
                    dataIndex: 'residentialName',
                    key: 'residentialName'
                },
                {
                    title: '所属区域',
                    dataIndex: 'areaName',
                    key: 'areaName'
                },
                {
                    title: '所属街道',
                    dataIndex: 'streetName',
                    key: 'streetName'
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    key: 'action',
                   scopedSlots: { customRender: 'action'}
                },
            ]
        }
    },
    mounted() {
        this.queryStreetDevice()
        this.getTypes()
    },
    filters: {
        filterTime(val) {
            return moment(val).format('YYYY-MM-DD HH:mm:ss') === 'Invalid date' ? '' : moment(val).format('YYYY-MM-DD HH:mm:ss')
        }
    },
    methods: {
        viewDetail(record){
            this.$refs.detail.showModal(record)
        },
        onSearch() {
            this.changePage(1, 10)
        },
        getTypes() {
            util.queryCenterGarbageType(this.$route.query.moduleCode).then(res => {
                this.types = res
            })
        },
        handleChange(e) {
            this.params.typeCode = e
            this.changePage(1, 10)
        },
        onChangeTime(e) {
            this.params.startTime = e[0]
            this.params.endTime = e[1]
            this.queryStreetDevice()
        },
        queryStreetDevice() {
            this.loading = true
            queryStreetDeviceApi({...this.params}, this.$route.query.moduleCode).then(res => {
                this.data = res.result.content
                this.pagination.total = res.result.totalElements
                this.loading = false
            })
        },
        changePage(current, size){
            this.pagination.current = current
            this.pagination.pageSize = size
            this.params.page = current
            this.params.limit = size
            this.queryStreetDevice()
        },
    }
}
</script>