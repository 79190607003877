<template>
    <a-modal v-model="visible" title="设备详情" width="700px" :footer="null">
        <a-descriptions >
            <a-descriptions-item label="设备编号">
                {{record.equipmentCode}}
            </a-descriptions-item>
            <a-descriptions-item label="设备名称">
                {{deviceResult.deviceName}}
            </a-descriptions-item>
            <a-descriptions-item label="厂商编号">
                {{deviceResult.factoryCode}}
            </a-descriptions-item>
            <a-descriptions-item label="设备类型">
                {{deviceResult.deviceType}}
            </a-descriptions-item>
        </a-descriptions>
    </a-modal>
</template>

<script>
import { queryStreetDeviceInfoApi } from '@/api/center'
export default {
    data() {
        return{
            visible: false,
            record: {},
            deviceResult: {}
        }
    },
    methods: {
        showModal(data) {
            this.visible = true
            this.record = data
            this.queryStreetDeviceInfo()
        },
        queryStreetDeviceInfo() {
            queryStreetDeviceInfoApi(this.record.equipmentCode).then(res => {
                this.deviceResult = res.result
            })
        }
    }
}
</script>