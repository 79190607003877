import service from './network';



/**
 *小区首页-本月
 * @param {} data 
 * @returns 
 */
 export function queryCenterMonthAnalyzeApi(data, code) {
    return service.request({
        url: '/street/center/trendToMonth',
        method: 'post',
        data,
        headers: { 'moduleToken': code}
    })
}

/**
 *小区首页-清洁屋
 * @param {} data 
 * @returns 
 */
 export function queryCenterHouseAnalyzeApi(data, code) {
    return service.request({
        url: '/site/analysis/realTime',
        method: 'post',
        data,
        headers: { 'moduleToken': code}
    })
}

/**
 *小区分析-列表
 * @param {} data 
 * @returns 
 */
 export function queryCenterAnalyzeApi(data, code) {
    return service.request({
        url: '/street/center/analysis',
        method: 'post',
        data,
        headers: { 'moduleToken': code}
    })
}

/**
 *清洁屋列表-列表
 * @param {} data 
 * @returns 
 */
 export function queryStreetPointApi(data, code) {
    return service.request({
        url: '/street/infrastructure/point/query',
        method: 'post',
        data,
        headers: { 'moduleToken': code}
    })
}

/**
 *清洁屋列表-清洁屋信息
 * @param {} data 
 * @returns 
 */
 export function queryStreetPointHouseApi(data, code) {
    return service.request({
        url: '/street/center/house/info/' + data,
        method: 'get',
        headers: { 'moduleToken': code}
    })
}

/**
 *设备表-列表
 * @param {} data 
 * @returns 
 */
 export function queryStreetDeviceApi(data, code) {
    return service.request({
        url: '/street/infrastructure/device/query',
        method: 'post',
        data,
        headers: { 'moduleToken': code}
    })
}

/**
 *设备表-设备信息
 * @param {} data 
 * @returns 
 */
 export function queryStreetDeviceInfoApi(data, code) {
    return service.request({
        url: '/street/center/device/info/' + data,
        method: 'get',
        headers: { 'moduleToken': code}
    })
}
/**
 *小区首页-今日
 * @param {} data 
 * @returns 
 */
 export function queryCenterTodayAnalyzeApi(data, code) {
    return service.request({
        url: '/street/center/trendToDay',
        method: 'post',
        data,
        headers: { 'moduleToken': code}
    })
}

/**
 *小区首页-本周
 * @param {} data 
 * @returns 
 */
 export function queryCenterWeekAnalyzeApi(data, code) {
    return service.request({
        url: '/street/center/trendToWeek',
        method: 'post',
        data,
        headers: { 'moduleToken': code}
    })
}

